/*
__Seed builder__
  (Read_only) Builder helper
*/
.flex {
  display: flex;
  justify-content: space-between; }

.flexCol {
  display: flex;
  flex-direction: column; }

.modalChild {
  padding: 30px;
  background-color: white; }

h1 {
  font-size: 20px; }

.declarationList {
  display: flex;
  flex-direction: column; }
  .declarationList button {
    width: 100%; }

.filterList {
  display: flex;
  flex-direction: column; }
  .filterList button {
    padding: 10px;
    margin-bottom: 10px; }

.docList {
  margin-right: 40px; }

.toRigth {
  margin-left: auto; }

.calc {
  justify-content: center;
  margin: 20px 0; }
  .calc:first-child {
    margin-top: 40px; }

/*=============================================================
  CHEVEZ.css v1.0.0
  Author: @Antony999k
  =============================================================*/
/* Resete HTML
  ---------------------------------------------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin-bottom: 20px; }

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
  display: block; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  color: #1BA0D0; }

li,
ul,
ol {
  list-style: none;
  padding: 0; }

button,
input,
optgroup,
select,
textarea {
  margin: 0; }

input,
textarea {
  font-size: 16px; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font-size: 16px; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    font-size: 16px; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    font-size: 16px; }
  input::placeholder,
  textarea::placeholder {
    font-size: 16px; }

button {
  padding: 0;
  background: none; }

button:hover,
a:hover {
  cursor: pointer; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* Global HTML
  ---------------------------------------------------------------*/
* {
  color: #00454d; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 32px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 20px; }

h6 {
  font-size: 18px; }

p,
a,
button {
  font-size: 16px; }

button:focus {
  outline: 0; }

/* Columns
  ---------------------------------------------------------------*/
.col2 {
  width: 15.66666666% !important; }

.col2Full {
  width: 16.66666666% !important; }

.col3 {
  width: 24% !important; }

.col3Full {
  width: 25% !important; }

.col4 {
  width: 32.33333333% !important; }

.col4Full {
  width: 33.3333333333% !important; }

.col5 {
  width: 40.66666% !important; }

.col5Full {
  width: 41.66666% !important; }

.col6 {
  width: 49% !important; }

.col6Full {
  width: 50% !important; }

.col7 {
  width: 57.3333% !important; }

.col7Full {
  width: 58.3333% !important; }

.col8 {
  width: 65.66666666666% !important; }

.col8Full {
  width: 66.66666666666% !important; }

.col9 {
  width: 74% !important; }

.col9Full {
  width: 75% !important; }

.col10 {
  width: 82.666666% !important; }

.col10Full {
  width: 83.666666% !important; }

.col12 {
  width: 100% !important; }

/* Buttons Types
  ---------------------------------------------------------------*/
.btn {
  padding: 3px 12px;
  border-radius: 4px;
  color: #00454d;
  margin-bottom: 0; }
  .btn * {
    color: #00454d; }
  .btn:hover {
    text-decoration: none;
    color: #00454d; }
    .btn:hover * {
      color: #00454d; }

.btnRegular {
  background-color: #E6EBED;
  border: 1px solid #D5DBDA; }
  .btnRegular:hover {
    background-color: #CFD3D5;
    border: 1px solid #CFD3D5; }
  .btnRegular:active {
    background-color: #B8BCBE;
    border: 1px solid #B8BCBE; }
  .btnRegular:disabled, .btnRegular[disabled] {
    background-color: #F0F3F4;
    border: 1px solid #F0F3F4;
    color: #9CA1A4;
    cursor: not-allowed; }

.btnOutline {
  background-color: none;
  border: 1px solid #00454d; }
  .btnOutline:hover {
    background-color: #00454d;
    border: 1px solid #00454d;
    color: white; }
    .btnOutline:hover * {
      color: white; }
  .btnOutline:active {
    background-color: #102F41;
    border: 1px solid #102F41;
    color: #CFD5D9; }
    .btnOutline:active * {
      color: #CFD5D9; }
  .btnOutline:disabled, .btnOutline[disabled] {
    background-color: #8A9DA8;
    border: 1px solid #8A9DA8;
    color: white;
    cursor: not-allowed; }
    .btnOutline:disabled *, .btnOutline[disabled] * {
      color: white; }

.btnGreen {
  border: 1px solid #01C070;
  background-color: #00D080;
  color: white; }
  .btnGreen * {
    color: white; }
  .btnGreen:hover {
    background-color: #10D080;
    color: white; }
    .btnGreen:hover * {
      color: white; }
  .btnGreen:active {
    background-color: #00b566;
    border-color: #00894b; }
    .btnGreen:active * {
      color: #00894b; }
  .btnGreen .text {
    color: white; }
  .btnGreen .icon {
    font-size: 20px; }
    .btnGreen .icon path {
      color: white; }

.btnOutlineGreen {
  border: 1px solid #00b566;
  color: #00b566; }
  .btnOutlineGreen * {
    color: #00b566; }
  .btnOutlineGreen:hover {
    background: #00b566;
    border: 1px solid #00b566;
    color: #fff; }
    .btnOutlineGreen:hover * {
      color: #fff; }
  .btnOutlineGreen:active {
    background: #00b566;
    border: 1px solid #00b566;
    color: #fff; }
    .btnOutlineGreen:active * {
      color: #fff; }
  .btnOutlineGreen:disabled, .btnOutlineGreen[disabled] {
    background-color: #8A9DA8;
    border: 1px solid #8A9DA8;
    color: white;
    cursor: not-allowed; }
    .btnOutlineGreen:disabled *, .btnOutlineGreen[disabled] * {
      color: #fff; }

.btnLink {
  border-color: rgba(255, 255, 255, 0); }
  .btnLink:hover {
    text-decoration: underline; }
  .btnLink:active {
    color: #102F41; }
  .btnLink:disabled, .btnLink[disabled] {
    color: #9CA1A4;
    cursor: not-allowed;
    text-decoration: none; }

/* Forms
  ---------------------------------------------------------------*/
.jumbotron {
  width: 100%;
  padding: 25px 25px;
  background: #00454d;
  color: white; }
  .jumbotron h4 {
    color: white;
    margin-bottom: 0px;
    font-size: 21px; }

.input,
.textarea {
  border-radius: 4px;
  border: 1px solid #626668;
  padding: 10px 13px;
  width: 100%; }
  .input::placeholder,
  .textarea::placeholder {
    color: #707070; }
  .input:focus,
  .textarea:focus {
    border: solid 1px #00454d;
    box-shadow: 0 0 3px #00454d; }
  .input:disabled, .input[disabled],
  .textarea:disabled,
  .textarea[disabled] {
    color: #9CA1A4;
    border-color: #9CA1A4; }
  .input:invalid,
  .textarea:invalid {
    border-color: #EF476F;
    background-color: rgba(239, 71, 111, 0.05); }

/* Input File */
.inputfile {
  background-color: #E6EBED;
  padding: 20px 25px;
  border-radius: 4px;
  border: 1px dashed #00454d;
  height: 76px;
  display: inline-block; }
  .inputfile::-webkit-file-upload-button {
    visibility: hidden; }
  .inputfile::before {
    content: 'Add a File';
    display: inline-block;
    cursor: pointer;
    padding: 7px 25px;
    border-radius: 4px;
    border: 1px solid #00454d; }

/* Select */
.selectContainer {
  position: relative;
  display: inline-block; }
  .selectContainer .arrow {
    position: absolute;
    display: inline-block;
    top: 2px;
    right: 0px;
    pointer-events: none; }
    .selectContainer .arrow svg {
      font-size: 36px; }
  .selectContainer select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    background-color: white;
    border: 1px solid #626668;
    border-radius: 4px;
    padding: 10px 34px 10px 15px; }

/* Switch */
.switch {
  position: relative;
  display: block;
  width: 47px;
  height: 25px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch input:checked + .slider {
    background-color: #00454d; }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px); }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9CA1A4;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px; }
    .switch .slider:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%; }

/* Checkbox */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 23px;
  line-height: 23px; }
  .checkbox-container:hover input ~ .checkmark {
    background-color: #f4f4f4; }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .checkbox-container input:checked ~ .checkmark {
      background-color: #00454d; }
    .checkbox-container input:checked ~ .checkmark:after {
      display: block; }
  .checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: white;
    border: 1px solid #00454d;
    border-radius: 4px; }
    .checkbox-container .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }

/* Radio Button */
.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 21px;
  line-height: 21px; }
  .radio-container:hover input ~ .checkmark {
    background-color: #f4f4f4; }
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .radio-container input:checked ~ .checkmark {
      background-color: white; }
    .radio-container input:checked ~ .checkmark:after {
      display: block; }
  .radio-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: white;
    border: 1px solid #00454d;
    border-radius: 50%; }
    .radio-container .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 3px;
      width: 13px;
      height: 13px;
      background-color: #00454d;
      border-radius: 50%; }

/* Alerts
  ---------------------------------------------------------------*/
.alert {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d6d8db;
  background-color: #e2e3e5;
  color: #383d41;
  padding: 15px 25px 15px 15px;
  display: inline-block; }
  .alert .btnClose {
    border: none;
    position: absolute;
    right: 5px;
    top: 5px; }

.alertError {
  background-color: #F7EBEE;
  border-color: #EF476F;
  color: #EF476F; }
  .alertError .text {
    color: #EF476F; }
  .alertError .btnClose .btn path {
    color: #930a2a; }

.alertWarning {
  background-color: f7efe3;
  border-color: #FFB74D;
  color: #FFB74D; }
  .alertWarning .text {
    color: #FFB74D; }
  .alertWarning .btnClose .btn path {
    color: #aa7427; }

.alertInfo {
  background-color: #E8F1F2;
  border-color: #1E919F;
  color: #1E919F; }
  .alertInfo .text {
    color: #1E919F; }
  .alertInfo .btnClose .btn path {
    color: #116d77; }

.alertSucces {
  background-color: #E8F3F0;
  border-color: #1BB494;
  color: #1BB494; }
  .alertSucces .text {
    color: #1BB494; }
  .alertSucces .btnClose .btn path {
    color: #149e80; }

/* Card
  ---------------------------------------------------------------*/
.cardNav {
  margin-bottom: -1px;
  position: relative;
  text-align: center; }
  .cardNav .item {
    background-color: white;
    border-radius: 4px 4px 0 0;
    padding: 9px 0;
    border-right: 1px solid #fafafa;
    border-left: 1px solid #fafafa;
    border-bottom: 0px;
    border-top: 0px;
    font-size: 15.5px; }
    .cardNav .item:hover {
      background-color: #F0F3F4; }
  .cardNav .activeLink .item {
    border-bottom: 2px solid #1BAAB0; }

.card,
.cardReduce {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background-color: white; }
  .card p,
  .cardReduce p {
    margin: 0;
    color: #143B51; }

.cardHeader {
  display: flex;
  padding: 11px 20px;
  border-bottom: 1px solid #D0D0D0;
  align-items: center; }
  .cardHeader h5 {
    font-size: 17px; }
  .cardHeader .title {
    margin: 0; }
  .cardHeader .btn .icon {
    display: none; }

.cardList,
.list {
  flex-grow: 1; }
  .cardList a,
  .list a {
    text-decoration: none; }
  .cardList .active .item,
  .list .active .item {
    background-color: #fafafa; }
    .cardList .active .item .idContainer .id,
    .list .active .item .idContainer .id {
      color: #fff;
      background: #1BAAB0; }
  .cardList .item,
  .list .item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #ddd; }
    .cardList .item:hover,
    .list .item:hover {
      background-color: #F7F7F7; }
    .cardList .item .idContainer,
    .list .item .idContainer {
      margin-right: 10px;
      text-align: center; }
      .cardList .item .idContainer .id,
      .list .item .idContainer .id {
        color: #1BAAB0;
        background: #fff;
        padding: 2px 5px;
        border-radius: 4px;
        border: 1px solid #1BAAB0; }
    .cardList .item .info,
    .list .item .info {
      margin-right: 10px; }
    .cardList .item svg,
    .list .item svg {
      fill: #D3D3D3;
      margin-left: -10px;
      margin-right: 5px; }
  .cardList .info .date,
  .list .info .date {
    color: #A3AEBF;
    font-size: 14px; }

/* Tabs
  ---------------------------------------------------------------*/
.tab .tablink {
  min-width: 120px;
  padding: 12px; }
  .tab .tablink:hover {
    background-color: #F0F3F4; }

.tab-primary .tablink {
  border: none;
  border-bottom: 1px solid #00454d;
  border-radius: 4px 4px 0px 0px; }
  .tab-primary .tablink.active {
    border: 1px solid #00454d;
    border-bottom: none; }

.tab-secondary .tablink {
  border: 1px solid #00454d; }
  .tab-secondary .tablink:first-child {
    border-radius: 4px 0px 0px 4px; }
  .tab-secondary .tablink:last-child {
    border-radius: 0px 4px 4px 0px; }
  .tab-secondary .tablink:not(:first-child) {
    border-left: none; }
  .tab-secondary .tablink.active {
    background-color: #00454d;
    color: white; }

/* Tags
  ---------------------------------------------------------------*/
.tag {
  color: white;
  border-radius: 4px;
  background-color: #00454d;
  padding: 5px;
  font-size: 12px;
  display: inline-block; }

.tag-error {
  background-color: #EF476F; }

.tag-warning {
  background-color: #FFB74D; }

.tag-info {
  background-color: #1BAAB0; }

.tag-succes {
  background-color: #1BB494; }

/* Lists
  ---------------------------------------------------------------*/
.list-item {
  border: 1px solid #F0F3F4;
  border-radius: 4px;
  padding: 15px 20px 20px 20px;
  max-width: 300px; }
  .list-item .list-item-top {
    display: flex;
    margin-bottom: 15px; }
    .list-item .list-item-top .title {
      font-size: 20px;
      flex-grow: 1; }
    .list-item .list-item-top .date {
      display: flex;
      align-items: center; }
      .list-item .list-item-top .date div {
        font-size: 12px;
        color: #626668;
        margin-right: 10px; }
        .list-item .list-item-top .date div:last-child {
          margin-right: 0px; }
  .list-item .info {
    margin-bottom: 15px;
    overflow: hidden;
    max-height: 55px; }
  .list-item .see-more {
    text-align: right; }
    .list-item .see-more button {
      color: #1BAAB0;
      border: none; }

/* Navigation
  ---------------------------------------------------------------*/
.navigation-toolbar {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #707070;
  display: flex;
  background-color: white;
  align-items: center;
  padding: 0px 40px;
  position: relative;
  z-index: 10; }
  .navigation-toolbar .nav ul {
    display: flex; }
    .navigation-toolbar .nav ul li {
      margin-right: 50px; }
  .navigation-toolbar .navigation-toolbar-right {
    display: flex;
    margin-left: auto;
    align-items: center; }
    .navigation-toolbar .navigation-toolbar-right .searchForm {
      margin-right: 40px;
      position: relative; }
      .navigation-toolbar .navigation-toolbar-right .searchForm input {
        border-radius: 4px;
        border: 1px solid #626668;
        padding: 5px 40px 5px 10px;
        background-color: #F5F5F5; }
      .navigation-toolbar .navigation-toolbar-right .searchForm .searchButton {
        color: #CBCBCB;
        position: absolute;
        right: 10px;
        top: 6px; }
        .navigation-toolbar .navigation-toolbar-right .searchForm .searchButton:hover {
          cursor: pointer; }
    .navigation-toolbar .navigation-toolbar-right .profile {
      display: flex;
      align-items: center; }
      .navigation-toolbar .navigation-toolbar-right .profile .imgUser {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        align-self: center;
        margin-right: 10px; }
        .navigation-toolbar .navigation-toolbar-right .profile .imgUser img {
          width: 100%;
          display: inline;
          margin: 0 auto;
          height: auto; }
      .navigation-toolbar .navigation-toolbar-right .profile p {
        margin: 0; }

/* Tables
  ---------------------------------------------------------------*/
table,
.table {
  border-collapse: collapse; }
  table tr,
  .table tr {
    border-bottom: 1px solid #E6EBED; }
    table tr:first-child,
    .table tr:first-child {
      border-width: 2px; }
    table tr td,
    table tr th,
    .table tr td,
    .table tr th {
      text-align: left;
      padding: 5px 10px; }

.table-dark tr:first-child {
  background-color: #00454d;
  border: none; }
  .table-dark tr:first-child td,
  .table-dark tr:first-child th {
    color: white; }
    .table-dark tr:first-child td:last-child,
    .table-dark tr:first-child th:last-child {
      border-radius: 0 4px 4px 0; }
    .table-dark tr:first-child td:first-child,
    .table-dark tr:first-child th:first-child {
      border-radius: 4px 0px 0px 4px; }

/* Drawer
  ---------------------------------------------------------------*/
.drawer {
  height: calc(100vh - 64px);
  padding: 25px 35px;
  background-color: #00454d;
  width: 360px;
  display: flex;
  flex-direction: column; }
  .drawer .btn-drawer {
    width: 100%;
    color: white;
    margin-bottom: 15px; }
    .drawer .btn-drawer:hover {
      background-color: white;
      color: #00454d; }
    .drawer .btn-drawer:last-child {
      margin-bottom: 0px; }
  .drawer .drawer-top {
    flex-grow: 1; }

/* Drag and Drop
  ---------------------------------------------------------------*/
.drop-area {
  border: 1px dashed #ccc;
  background-color: #F0F3F4;
  border-radius: 4px;
  width: 380px;
  padding: 80px 60px; }
  .drop-area.highlight {
    border-color: #00454d; }
  .drop-area .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px; }
  .drop-area form {
    margin-bottom: 10px;
    text-align: center; }
    .drop-area form #filesInput {
      display: none; }
    .drop-area form svg {
      font-size: 80px;
      margin-bottom: 20px; }
  .drop-area .gallery {
    margin-top: 10px; }
    .drop-area .gallery .img {
      width: 150px;
      margin-bottom: 10px;
      margin-right: 10px;
      vertical-align: middle; }

/* Modal
  ---------------------------------------------------------------*/
.modal {
  width: 681px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.27); }
  .modal .modal-header {
    display: flex;
    align-items: center;
    background-color: #00454d;
    padding: 30px; }
    .modal .modal-header .title {
      color: white;
      flex-grow: 1;
      margin: 0; }
    .modal .modal-header button {
      border: none; }
    .modal .modal-header svg {
      fill: white; }
  .modal .modal-body {
    padding: 30px; }
    .modal .modal-body .modal-body-paragraph {
      margin-bottom: 30px; }
      .modal .modal-body .modal-body-paragraph:last-child {
        margin-bottom: 0px; }
      .modal .modal-body .modal-body-paragraph .subtitle {
        margin-bottom: 15px; }
      .modal .modal-body .modal-body-paragraph p {
        margin: 0; }

/* Helpers
  ---------------------------------------------------------------*/
.emptyList {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 400px;
  padding: 70px; }
  .emptyList .icon {
    font-size: 100px;
    margin-bottom: 30px; }
    .emptyList .icon path {
      color: #9ca0a8; }
  .emptyList p {
    color: #9ca0a8 !important; }

.module {
  overflow-y: auto; }

.title {
  font-weight: bold;
  font-size: 48px;
  color: #007473; }

.button {
  padding: 10px;
  margin: 10px 4px;
  border: none;
  border-radius: 4px;
  background-color: #e6ebed; }
  .button:hover {
    background-color: #1a9bac;
    color: white; }

.right {
  float: right; }

.clickable {
  cursor: pointer; }

.fullscreen {
  height: 100%;
  width: 100%; }

.module {
  position: fixed;
  width: 100%;
  height: 100%; }

.font {
  font-family: "Oxygen", sans-serif; }

.linebreak {
  margin-top: 20px; }

.break {
  margin-top: 20px; }

.sectionColored {
  background-color: #e6ebed; }

.sectionBordered {
  border-radius: 4px;
  border: 1px solid #f0f3f4; }

.listItem {
  box-sizing: border-box;
  background-color: inherit;
  color: #007473;
  border: 1px solid #007473;
  padding: 12px; }
  .listItem.listItemActive {
    background-color: #e6ebed; }

/*
=======================Flex and Alignment=========================
*/
.flex {
  display: flex;
  justify-content: left; }

.flexCol {
  display: flex;
  flex-direction: column; }

/*
=======================Buttons=========================
*/
.button {
  padding: 10px;
  margin: 10px 4px;
  border: none;
  border-radius: 4px;
  background-color: #e6ebed; }
  .button:hover {
    background-color: #1a9bac;
    color: white; }
  .button.buttonOutline {
    background-color: white;
    border: 1px solid #007473;
    color: #007473; }
    .button.buttonOutline:hover {
      background-color: #007473;
      color: white; }

.badge {
  background-color: #ef476f;
  color: white; }

.txt, .field, .select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 12px 12px;
  background-color: #e6ebed;
  border: none;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px; }
  .txt:focus, .field:focus, .select:focus {
    border: 1px solid #1a9bac;
    box-shadow: 10px, 10px, 30px, #000; }
  .txt:hover, .field:hover, .select:hover {
    box-shadow: 10px, 10px, 30px, #000; }

.field {
  padding: 0px; }

.select {
  font-size: 16px;
  padding: 8px; }

.alert {
  padding: 12px;
  border: 1px solid #feb74c;
  background-color: rgba(254, 183, 76, 0.1);
  color: #feb74c;
  border-radius: 4px;
  margin-bottom: 12px; }
